import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TestnewMap from './Testnewmaps';
import ErrorPage from './ErrorPage';
import LoginPage from './LoginPage';
import AfterLogin from './AfterLogin';

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<LoginPage />} /> */}
        <Route path="/" element={<LoginPage />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/main" element={<TestnewMap />} />
        <Route path="/loginhandler" element={<AfterLogin />} />
      </Routes>
    </Router>
  );
}

export default App;
