import axios from 'axios';
import 'aws-sdk';

function AfterLogin() { 
    const AWS = require('aws-sdk');
    AWS.config.update({region: 'eu-west-1'});
    const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();

    var code = '';

    const HandleUserController = () => {
        //Get code
        const urlParams = new URLSearchParams(window.location.search);
        console.log("urlparams:" + urlParams);
        code = urlParams.get('code');
        var successful = false;
        console.log("code:" + code);
      
        if (code !== null && code !== ''){
    
          //Send through data from user pool
          axios.post('https://m7d97w17c7.execute-api.eu-west-1.amazonaws.com/test1', { 'code': code })
          .then(response => {
            const returnedIdToken = response.data['token'];
            const returnedUserId = response.data['user_id'];
            console.log("code:" + returnedIdToken);
            console.log("userId:" + returnedUserId);

            window.location.assign(`https://www.cvcdevelopment.com/main?tokenId=${encodeURIComponent(returnedIdToken)}&userId=${encodeURIComponent(returnedUserId)}`);
          })


          //.then(response => window.location.assign('https://www.cvcdevelopment.com/main'));
          //.catch(error => setErrorMessage('Incorrect username or password. Please try again.'));
          successful = true;

        //   cognitoIdentityServiceProvider.getUser(params, (err, data) => {
        //     if (err) {
        //         console.log(err); // an error occurred
        //         //window.location.href = '/errorpage';
        //     } else {
        //         console.log(data); // successful response
    
        //         //Send through data from user pool
        //           axios.post('https://localhost:5001/user/handleuser', { 'userdata': data })
        //           .then(response => window.location.assign('https://www.cvcdevelopment.com/main'))
        //           .catch(error => setErrorMessage('Incorrect username or password. Please try again.'));
        //           successful = true;
        //     };          
        // });
      }
      else{
        console.log("Code is null");
        window.location.href = '/errorpage';
      }

    return successful;
  }

  var userResults = HandleUserController();
  if (userResults){
    // const loginUrl = `https://www.cvcdevelopment.com/main?code=${encodeURIComponent(code)}`;
    
    // // Redirect user to the Cognito Hosted UI
    // window.location.assign(loginUrl);
  }
  else{
    const loginUrl = `https://www.cvcdevelopment.com/main?code=${encodeURIComponent(code)}`;
    
    // Redirect user to the Cognito Hosted UI
    window.location.assign(loginUrl);
  }
}

export default AfterLogin;