import React, { useState, useEffect } from "react";
import axios from 'axios';

// MapWithDrawing.jsx
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {
  GoogleMap,
  Marker,
  Polyline,
  useJsApiLoader,
  Autocomplete,
} from "@react-google-maps/api";
import "./styles.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
  },
};

const MAP_LIBRARIES = ["places"];

const DEFAULT_MAP_CENTER = { lat: -25.741777071637294, lng: 28.529918602013908 };

function MapWithDrawing() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [IdToken, setIdToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [drawingPoints, setDrawingPoints] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [activeTab, setActiveTab] = useState("Home");
  const [map, setMap] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [graphData, setGraphData] = useState([]);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDbK8Rr-ecn7dWpIe_B5WPUsw1XOAtpgbM",
    libraries: MAP_LIBRARIES,
  });

  useEffect(() => {
    checkAuthentication().then(isAuth => {
      if (!isAuth) {
        // If not authenticated, redirect to an error page
        window.location.href = '/ErrorPage'; // Adjust the URL to your error page
      } else {
        // If authenticated, set isAuthenticated state to true to render the component
        setIsAuthenticated(true);
      }
    });
  }, []);

  const checkAuthentication = () => {
    return new Promise((resolve) => {
      //Get code
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      if (code) {
        //Send through data from user pool
        axios.post('https://m7d97w17c7.execute-api.eu-west-1.amazonaws.com/test1', { 'code': code })
          .then(response => {
            const returnedIdToken = response.data['token'];
            const returnedUserId = response.data['user_id'];

            if (returnedIdToken && returnedUserId) {
              setIdToken(returnedIdToken);
              setUserId(returnedUserId);
              resolve(true);
            }
            else {
              resolve(false);
            }
          })
          .catch(error => {
            console.error('Auth error: ', error);
            resolve(false);
          })
      }
      else {
        resolve(false);
      }
    });
  };

  // Fetch data from API or set it manually
  useEffect(() => {
    // Example API response structure
    const apiResponse = [
      {
        title: 'Location 1',
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'Sensor 1',
            data: [0, 1, 2, 3, 4, 5, 6],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'Sensor 2',
            data: [5, 1, 4, 2, 4, 5, 9],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      },
      // Add more entries as needed
      {
        title: 'Location 2',
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'Sensor 1',
            data: [0, 1, 2, 3, 4, 5, 6],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'Sensor 2',
            data: [5, 1, 4, 2, 4, 5, 9],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      },
    ];

    setGraphData(apiResponse);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      setAutocomplete(new window.google.maps.places.AutocompleteService());
    }
  }, [isLoaded]);

  if (isAuthenticated === null) {
    // Render nothing or a loading indicator until the authentication check is complete
    return <div>Loading...</div>;
  }


  const handleLogout = () => {
    // Perform logout actions, such as clearing user data or redirecting to the login page
    // For simplicity, let's assume it's just redirecting to the login page
    window.location.href = '/';
  };

  const onClick = (e) => {
    if (isDrawing) {
      const clickedPoint = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      setDrawingPoints((prevPoints) => [...prevPoints, clickedPoint]);
    }
  };

  const startDrawing = () => {
    setDrawingPoints([]);
    setIsDrawing(true);
  };

  const stopDrawing = () => {
    setIsDrawing(false);
    if (drawingPoints.length > 1) {
      const firstPoint = drawingPoints[0];
      if (drawingPoints[0] !== drawingPoints[drawingPoints.length - 1])
        setDrawingPoints((prevPoints) => [...prevPoints, firstPoint]);
    }
  };

  const resetDrawing = () => {
    setDrawingPoints([]);
    setIsDrawing(false);
  };

  const undoDrawing = () => {
    setDrawingPoints((prevPoints) => {
      if (prevPoints.length > 0) {
        return prevPoints.slice(0, -1);
      }
      return prevPoints;
    });
  };

  const viewCurrentBoundry = () => {
    //Make api call
    return new Promise((resolve) => {
      //Send through userid and token to get coordinates
      axios.post('https://m7d97w17c7.execute-api.eu-west-1.amazonaws.com/test1/get_area_coordinates',
        { 'userid': userId },
        {
          headers: {
            'Content-Type': 'application/json',
            'code': IdToken
          }
        })
        .then(response => {
          const userid_temp = response.data['msg'];
          const err = response.data['err'];
          const coordinates = response.data['coordinates'];

          if (coordinates && userid_temp && !err) {
            const formattedCoordinates = coordinates.map(coord => ({
              lat: parseFloat(coord.lat),
              lng: parseFloat(coord.long)
            }));

            setIsDrawing(true);
            setDrawingPoints(formattedCoordinates);
            setIsDrawing(false);
            resolve(true);
          }
          else {
            resolve(false);
          }
        })
        .catch(error => {
          console.error('Fetching coordinates error: ', error);
          resolve(false);
        })
    });
  };

  const saveNewBoundry = () => {
    let currentAreaCoordinates = drawingPoints;

    if (!currentAreaCoordinates || currentAreaCoordinates.length < 1)
      return;

    console.log("Before convert: " + currentAreaCoordinates);

    const stringCurrentAreaCoordinates = currentAreaCoordinates.map(coord => ({
      lat: coord.lat.toString(),
      long: coord.lng.toString()
    }));

    console.log("After convert: " + stringCurrentAreaCoordinates);

    //Make api call
    return new Promise((resolve) => {
      //Send through userid and token to get coordinates
      axios.post('https://m7d97w17c7.execute-api.eu-west-1.amazonaws.com/test1/set_area_coordinates',
        { 'userid': userId, 'coordinates': stringCurrentAreaCoordinates },
        {
          headers: {
            'Content-Type': 'application/json',
            'code': IdToken
          }
        })
        .then(response => {
          const output = response.data['output'];

          if (output) {
            console.log(output);
          }
          else {
            resolve(false);
          }
        })
        .catch(error => {
          console.error('Saving coordinates error: ', error);
          resolve(false);
        })
    });
  };

  const onLoad = (map) => {
    setMap(map);
    const listener = map.addListener("idle", () => {
      window.google.maps.event.trigger(map, "resize");
    });

    return () => {
      window.google.maps.event.removeListener(listener);
    };
  };

  const handlePlaceSelect = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace && autocomplete.getPlace();

      if (place && place.geometry) {
        const location = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        map.panTo(location);
      } else {
        handleAutocompleteError(place);
      }
    } else {
      console.error("Autocomplete is not available");
    }
  };

  const handleAutocompleteError = (place) => {
    console.error("Selected location doesn't exist or is not supported:", place);
    // You can display a user-friendly error message or take other actions as needed
    // For example, show a notification or update the UI to inform the user
  };

  if (loadError) {
    return <div>Error loading Google Maps API</div>;
  }

  const renderHomeTab = () => {
    return (<div className="content">
      <h2>Welcome to Your App</h2>
      {/* Release Notes Block */}
      <div className="release-notes">
        <h3>Release Notes</h3>
        <pre>{releaseNotes}</pre>
      </div>
    </div>);
  };

  const renderMapTab = () => (
    <div className="content">
      <Autocomplete
        onLoad={(autocomplete) => setAutocomplete(autocomplete)}
        onPlaceChanged={handlePlaceSelect}
      >
        <input
          type="text"
          placeholder="Search for a location"
          style={{ width: "100%" }}
        />
      </Autocomplete>
      <button onClick={startDrawing}>Start Drawing</button>
      <button onClick={stopDrawing}>Stop Drawing</button>
      <button onClick={resetDrawing}>Reset</button>
      <button onClick={undoDrawing}>Undo</button>
      <button onClick={viewCurrentBoundry}>View Current Boundary</button>
      <button onClick={saveNewBoundry}>Save Boundary</button>

      <GoogleMap
        mapContainerClassName="MapWithDrawing-map"
        center={DEFAULT_MAP_CENTER}
        zoom={12}
        version="weekly"
        onClick={onClick}
        onLoad={onLoad}
      >
        {drawingPoints.map((point, index) => (
          <Marker key={index} position={point} />
        ))}
        {drawingPoints.length > 0 && (
          <Polyline
            path={drawingPoints}
            options={{ strokeColor: "#FF0000", strokeWeight: 2 }}
          />
        )}
      </GoogleMap>

      {drawingPoints.length > 0 && (
        <div>
          <strong>Drawing Points:</strong>
          <ul>
            {drawingPoints.map((point, index) => (
              <li key={index}>{`Lat: ${point.lat.toFixed(6)}, Lng: ${point.lng.toFixed(6)}`}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );

  //Draw graphs dynamically
  const renderSensorDataTab = () => {
    return <div className="sensordata-container">
      {graphData.map((entry, index) => (

        <div key={index}>
          <h2>{entry.title}</h2>
          <Line options={options} data={entry} />
        </div>

      ))}
    </div>
  };

  const renderContactTab = () => <div className="content">Contact content goes here</div>;

  const renderSettingsTab = () => <div className="content">Settings content goes here</div>;

  // Placeholder for release notes content
  const releaseNotes = `
    Version 1.0.0 (January 2024):
    - Initial release
    - Added map functionality
    - Improved user interface`
    ;

  if (isAuthenticated) {
    return (
      <div className="page-container">
        <div className="tabs-container">
          <div className="tabs">
            <button onClick={() => setActiveTab("Home")}>Home</button>
            <button onClick={() => setActiveTab("Map")}>Map</button>
            <button onClick={() => setActiveTab("SensorData")}>Sensor Data</button>
            <button onClick={() => setActiveTab("Contact")}>Contact</button>
            <button onClick={() => setActiveTab("Settings")}>Settings</button>
            <button onClick={() => handleLogout()}>Log Out</button>
          </div>
        </div>
        <div className="content-container">
          {activeTab === "Home" && renderHomeTab()}
          {activeTab === "Map" && isLoaded && renderMapTab()}
          {activeTab === "SensorData" && renderSensorDataTab()}
          {activeTab === "Contact" && renderContactTab()}
          {activeTab === "Settings" && renderSettingsTab()}
        </div>
      </div>
    );
  }
}

export default MapWithDrawing;
