function LoginPage() {
    const domain = "cvcdevelopment";
    const clientId = "41uhp93c08n44dh89eglnsuied";
    const responseType = "code"; // or 'code' if you're using authorization code grant
    const scope = "openid";
    const redirectUri = encodeURIComponent("https://www.cvcdevelopment.com/main");
  
    const loginUrl = `https://${domain}.auth.eu-west-1.amazoncognito.com/login?client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${redirectUri}`;
  
    // Redirect user to the Cognito Hosted UI
    window.location.assign(loginUrl);
  }

export default LoginPage;